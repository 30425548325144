import ServiceApi from "../services/services.api";
ServiceApi.defaults.headers.common['lang'] = localStorage.getItem("langAdmin") ? localStorage.getItem("langAdmin") : 'fa';

const ApiSubscription = {
  getSubscriptionList: (skip, take, companyid) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/get/list?skip=${skip}&take=${take}&companyid=${companyid}`,
    };
    return ServiceApi(options);
  },
  saveSubscription: (data) => {
    const options = {
      method: 'POST',
      data: data,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/save`,
    };
    return ServiceApi(options);
  },
  setSubscriptionStatus: (id,status) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/set/${id}/paid/${status}`,
    };
    return ServiceApi(options);
  },
  setSubscriptionDetailStatus: (id,status) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/detail/set/${id}/paid/${status}`,
    };
    return ServiceApi(options);
  },
  deleteSubscription: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/delete/${id}`,
    };
    return ServiceApi(options);
  },
  deleteSubscriptionDetail: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/detail/delete/${id}`,
    };
    return ServiceApi(options);
  },
  saveSubscriptionDetail: (data) => {
    const options = {
      method: 'POST',
      data: data,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("tokenAdmin")}`
      },
      url: `/subscription/detail/save`,
    };
    return ServiceApi(options);
  },
  getSubscriptionListAll: (skip, take, data) => {
    const options = {
      method: 'POST',
      data:data,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/subscription/get/All?skip=${skip}&take=${take}`,
    };
    return ServiceApi(options);
  },
};
export default ApiSubscription;

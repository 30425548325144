import React from 'react';
import { Segment, Message, Header } from 'semantic-ui-react';

const ErrorBox = ({ message}) => {

  return <>
    <Segment basic className='w-100 m-1'>
      <Message negative>
        {message}
      </Message>
    </Segment>
  </>
}

export default ErrorBox
import React from "react";
import { CirclesWithBar } from 'react-loader-spinner';

const CustomLoader = ({ full = false }) => {
    return (
        <CirclesWithBar
            height="100"
            width="100"
            color="#a41d19"
            wrapperClass={`custom-loader ${full==false ? '' : 'full'}`}
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel='circles-with-bar-loading'
        />
    )
}

export default CustomLoader
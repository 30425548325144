import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";

const DataPopup = ({ text, value }) => {

  return (
    <Popup
    flowing
    style={{maxWidth:'450px'}}
      content={value}
      position="bottom right"
      on="click"
      trigger={<div className="popup-text">{text}</div>}
    />
  );
};

export default DataPopup;

import React from 'react';
import SubscriptionsAll from '../../containers/SubscriptionsAll';
import { useParams, useSearchParams } from 'react-router-dom';

const SubscriptionDetailsPage = () => {
  const {page} = useParams()
  const { c } = useSearchParams()

  
  return (
    <SubscriptionsAll  page={page} perPage={c} />
  );
}

export default SubscriptionDetailsPage;

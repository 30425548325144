import React, { useState, useContext, useEffect } from "react";
import { Header, Grid, Segment, Modal, Divider, Button, Popup, Dropdown } from "semantic-ui-react";
import ApiSubscription from "../../core/api/api.subscription";
import ServiceNotification from "../../core/services/services.notification";
import ReactPaginate from "react-paginate";
import CustomLoader from "../../components/CustomLoader";
import SubscriptionAllCard from "../../components/SubscriptionAllCard";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import AdvanceSearchSubscription from "../../components/AdvanceSearchSubscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import ErrorBox from "../../components/ErrorBox";

const SubscriptionsAll = ({ page, perPage = 50 }) => {

  const [itemsPerPage, setItemPerPage] = useState(perPage); //take
  const skip = page ? Number(page) - 1 : 0
  const [itemOffset, setItemOffset] = useState(skip * itemsPerPage); //skip

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [error, seterror] = useState(null);
  const [open, setOpen] = useState(false);
  let [searchParams] = useSearchParams();
  const [isfiltered, setisfiltered] = useState(false)

  const [listData, setListData] = useState(null);


  const initialQry = {
    companyid: 0,
    justDeptors: null,
    from: null,
    to: null,
  }
  const [data, setData] = useState(initialQry);

  useEffect(() => {
    getSubscriptionListHandler();
  }, [page, itemOffset, searchParams]);

  const getSubscriptionListHandler = async () => {
    let qry = Object.fromEntries(searchParams.entries())
    if (searchParams.size == 0) {
      setisfiltered(false)
    } else {
      setisfiltered(true)
    }
    let tempskip = page ? Number(page) - 1 : 0
    let offset = tempskip * itemsPerPage

    try {
      const resp = await ApiSubscription.getSubscriptionListAll(
        offset,
        itemsPerPage,
        qry
      );
      if (resp.data.status == "success") {
        setListData(resp.data.data);
        setItemCount(Number(resp.data.message));
        setPageCount(Math.ceil(Number(resp.data.message) / itemsPerPage));
      } else {
        seterror(resp.data);
      }
    } catch (e) {
      console.log(e);
      ServiceNotification.show("خطا", "", "danger");
    }
  };

  const navigate = useNavigate()
  const handlePageClick = (event) => {
    navigate({
      pathname: `/subscription/list/${event.selected + 1}`,
      search: searchParams.toString(),
    });

    const newOffset = (event.selected * itemsPerPage) % Number(itemCount);
    setItemOffset(newOffset);
  };


  const filterHandler = async () => {
    setOpen(false)
    let temp_qry = data
    for (let key in temp_qry) {
      if (temp_qry[key] == null) {
        delete temp_qry[key];
      }
    }
    let newPage = searchParams.size == 0 ? 1 : page

    navigate({
      pathname: `/subscription/list/${newPage}`,
      search: createSearchParams(temp_qry).toString(),
    });
    const newOffset = ((newPage - 1) * itemsPerPage) % Number(itemCount);
    setItemOffset(newOffset);
    setData(initialQry)
  }


  const removeFilters = () => {
    let newPage = 1
    navigate({
      pathname: `/subscription/list/${newPage}`,
      search: ""
    });
    const newOffset = 0;
    setItemOffset(newOffset);
    setData(initialQry)
  }


  const setSearchData = (name, value, usable = false) => {
    setData((prevState) => (
      {
        ...prevState,
        [name]: value
      }
    ))
  }

  const perPageCount = [
    { key: 0, value: 50, text: "50" },
    { key: 1, value: 100, text: "100" },
    { key: 2, value: 150, text: "150" },
    { key: 3, value: 200, text: "200" },
    { key: 4, value: 250, text: "250" },

  ];


  return (
    <>
      <div className="section-header">
        <Header as="h1" size="medium" className="m-0">
          سوابق مالی
        </Header>
        <div className="d-flex flow-row ai-center jc-center">
          <Button color="blue" type="button" onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faFilter} /> &nbsp; جستجو
          </Button>
          {
            isfiltered == true ?
              <Popup
                position="bottom center"
                trigger={
                  <Button color="red" className="" type="button" onClick={() => removeFilters()}>
                    <FontAwesomeIcon icon={faFilterCircleXmark} />
                  </Button>
                }
              >
                <Popup.Content>حذف فیلتر ها</Popup.Content>
              </Popup>

              : null
          }
        </div>
      </div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        centered
        fluid
      >
        <Modal.Content className="text-center">
          <div className="ui header tiny">فیلتر سوابق مالی</div>
          <Divider />
          <AdvanceSearchSubscription
            data={data}
            setData={setSearchData}
            getList={filterHandler}
          />
        </Modal.Content>
      </Modal>
      <Segment basic className="myxsegment">
        <Grid>
          {listData == null && error == null ? (
            <Grid.Column width={16}>
              <Segment>
                <CustomLoader />
              </Segment>
            </Grid.Column>
          ) : error == null &&
            listData.subscribes ?
            <>
              <Grid.Row
                className="ui segment border-none autoHide sticky-table-head m-1"
                verticalAlign="middle"
              >
                <Grid.Column width={1}>کد</Grid.Column>
                <Grid.Column width={3}>شرح</Grid.Column>
                <Grid.Column width={2}>شرکت</Grid.Column>
                <Grid.Column width={2}>سال</Grid.Column>
                <Grid.Column width={2}>صورت حساب</Grid.Column>
                <Grid.Column width={2}>بدهی</Grid.Column>
                <Grid.Column width={2}>پرداختی</Grid.Column>
                <Grid.Column width={1}>وضعیت</Grid.Column>
                <Grid.Column width={1}>عملیات</Grid.Column>
              </Grid.Row>
              {
                listData.subscribes.length > 0 ? (
                  listData.subscribes.map((item, index) => {
                    return (
                      <Grid.Row
                        className="p-0-b"
                        verticalAlign="middle"
                        key={index}
                      >
                        <SubscriptionAllCard
                          item={item}
                          getList={getSubscriptionListHandler}
                        />
                      </Grid.Row>
                    );
                  })
                ) : <Grid.Column width={16} className="ui segment border-none">
                  موردی برای نمایش وجود ندارد
                </Grid.Column>
              }
              <Grid.Row className="ui segment tertiary m-1" verticalAlign="middle">
                <Grid.Column width={7}></Grid.Column>
                <Grid.Column width={3}><strong style={{ color: '#000' }}>{listData?.totalPaid ? `جمع صورت حساب : ${Number(listData.totalPrice).toLocaleString()} ریال` : ""}</strong></Grid.Column>
                <Grid.Column width={3}><strong style={{ color: '#000' }}>{listData?.totalDebt ? `جمع بدهی : ${Number(listData.totalDebt).toLocaleString()} ریال` : ""}</strong></Grid.Column>
                <Grid.Column width={3}><strong style={{ color: '#000' }}>{listData?.totalPaid ? `جمع پرداختی : ${Number(listData.totalPaid).toLocaleString()} ریال` : ""}</strong></Grid.Column>

              </Grid.Row>
              <Grid.Row className="" textAlign="center">
                  <Divider hidden />
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="بعدی"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="قبلی"
                    renderOnZeroPageCount={null}
                    containerClassName="ui pagination  menu tiny m-auto"
                    pageClassName="item"
                    activeClassName="active"
                    nextClassName="item d-none"
                    previousClassName="item d-none"
                    breakClassName="item"
                    pageLinkClassName="customPagItem"
                  />
              </Grid.Row>
            </>
            : listData == null && error != null ? (
              <ErrorBox message={error.message} />
            ) : <Grid.Column width={16}>
              <Segment>موردی برای نمایش وجود ندارد</Segment>
            </Grid.Column>
          }
        </Grid>
      </Segment >



    </>
  );
};

export default SubscriptionsAll;

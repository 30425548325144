import React, { Suspense, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import CustomLoader from "./components/CustomLoader";
import { MyContextProvider } from "./MyContext";
import "./App.css";
import { useTranslation } from "react-i18next";
import SubscriptionDetailsPage from "./pages/SubscriptionDetailsPage";

const LoginLayout = lazy(() => import('./layouts/LoginLayout'))
const RootLayout = lazy(() => import('./layouts/RootLayout'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const Dashboard = lazy(() => import('./pages/DashboardPage'))
const AccountPage = lazy(() => import('./pages/AccountPage'))
const TenderSavePage = lazy(() => import('./pages/TenderSavePage'))
const TenderListPage = lazy(() => import('./pages/TenderListPage'))
const SubscriptionPage = lazy(() => import('./pages/SubscriptionPage'))
const TicketSavePage = lazy(() => import('./pages/TicketSavePage'))
const TicketListPage = lazy(() => import('./pages/TicketListPage'))
const TicketViewPage = lazy(() => import('./pages/TicketViewPage'))
const BreederSavePage = lazy(() => import('./pages/BreederSavePage'))
const BreederListPage = lazy(() => import('./pages/BreederListPage'))
const CertificateSavePage = lazy(() => import('./pages/CertificateSavePage'))
const CertificateListPage = lazy(() => import('./pages/CertificateListPage'))
const ArticleListPage = lazy(() => import('./pages/ArticleListPage'))
const ArticleSavePage = lazy(() => import('./pages/ArticleSavePage'))
const CompanyListPage = lazy(() => import('./pages/CompanyListPage'))
const CompanySavePage = lazy(() => import('./pages/CompanySavePage'))
const FormsListPage = lazy(() => import('./pages/FormsListPage'))
const CountryListPage = lazy(() => import('./pages/CountryListPage'))
const CountrySavePage = lazy(() => import('./pages/CountrySavePage'))
const TicketBroadcastPage = lazy(() => import('./pages/TicketBroadcastPage'))
const StaticPageSavePage = lazy(() => import('./pages/StaticPageSavePage'))
const StaticPageListPage = lazy(() => import('./pages/StaticPageListPage'))
const UserListPage = lazy(() => import('./pages/UserListPage'))
const AdminListPage = lazy(() => import('./pages/AdminListPage'))
const TranslationPage = lazy(() => import('./pages/TranslationPage'))
// const SettingsPage = lazy(() => import('./pages/SettingsPage'))



const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("langAdmin") == null) {
      localStorage.setItem("langAdmin", "fa");
    }
    if (localStorage.getItem("langAdmin") == "fa") {
      document.body.classList.add("lang-fa");
      document.body.classList.remove("lang-en");
      i18n.changeLanguage("fa");
    } else {
      document.body.classList.remove("lang-fa");
      document.body.classList.add("lang-en");
      i18n.changeLanguage("en");
    }
  },[]);
  return (
    <MyContextProvider>
      <BrowserRouter>
        <ReactNotifications />
        <Suspense fallback={<CustomLoader />}>
          <Routes>
            {/* <Route element={<LoginLayout />}>
              <Route
                exact
                path="/register"
                element={<RegisterForm />}
              />
            </Route> */}
            <Route element={<LoginLayout />}>
              <Route exact path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<RootLayout />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/account" element={<AccountPage />} />
              <Route path="/country/save">
                <Route path=":id" element={<CountrySavePage />} />
                <Route path="" element={<CountrySavePage />} />
              </Route>

              <Route path="/country/list" element={<CountryListPage />} />

              <Route path="/tender/save">
                <Route path=":id" element={<TenderSavePage />} />
                <Route path="" element={<TenderSavePage />} />
              </Route>
              <Route path="/tender/list" element={<TenderListPage />} />
              <Route path="/subscription/:id" element={<SubscriptionPage />} />

              <Route path="/subscription/list">
                <Route path=":page" element={<SubscriptionDetailsPage />} />
                <Route path="" element={<SubscriptionDetailsPage />} />
              </Route>




              <Route path="/ticket/save/single">
                <Route path=":id" element={<TicketSavePage />} />
                <Route path="" element={<TicketSavePage />} />
              </Route>

              <Route path="/ticket/save/all">
                <Route path=":id" element={<TicketBroadcastPage />} />
                <Route path="" element={<TicketBroadcastPage />} />
              </Route>

              <Route path="/ticket/list" element={<TicketListPage />} />
              <Route path="/ticket/view/:id" element={<TicketViewPage />} />

              <Route path="/user/list" element={<UserListPage />} />

              <Route path="/admin/user/list" element={<AdminListPage />} />

              <Route path="/breeder/save">
                <Route path=":id" element={<BreederSavePage />} />
                <Route path="" element={<BreederSavePage />} />
              </Route>

              <Route path="/breeder/list" element={<BreederListPage />} />

              <Route path="/certificate/save">
                <Route path=":id" element={<CertificateSavePage />} />
                <Route path="" element={<CertificateSavePage />} />
              </Route>


              <Route path="/certificate/list">
                <Route path=":active/:page" element={<CertificateListPage />} />
                <Route path=":page" element={<CertificateListPage />} />
                <Route path="" element={<CertificateListPage />} />
              </Route>



              {/* <Route
                path="/certificate/list"
                element={<CertificateListPage />}
              /> */}

              <Route path="/page/save">
                <Route path=":slug" element={<StaticPageSavePage />} />
                <Route path="" element={<StaticPageSavePage />} />
              </Route>

              <Route path="/page/list" element={<StaticPageListPage />} />

              <Route path="/article/save">
                <Route path=":slug" element={<ArticleSavePage />} />
                <Route path="" element={<ArticleSavePage />} />
              </Route>

              <Route path="/article/list" element={<ArticleListPage />} />

              {/* <Route path="/company/list/:page" element={<CompanyListPage />} /> */}
              <Route  path="/company/list">
                <Route path=":active/:page" element={<CompanyListPage />} />
                <Route path=":page" element={<CompanyListPage />} />
                <Route path="" element={<CompanyListPage />} />
              </Route>


              <Route path="/company/save">
                <Route path=":id" element={<CompanySavePage />} />
                <Route path="" element={<CompanySavePage />} />
              </Route>

              <Route path="/forms/list" element={<FormsListPage />} />
              
              <Route path="/edit/translations" element={<TranslationPage />} />
              {/* <Route path="/settings" element={<SettingsPage />} /> */}
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </MyContextProvider>
  );
};

export default App;

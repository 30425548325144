import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const ServiceNotification = {
    show: (title , message , type) => {
        Store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
        });
    }
}

export default ServiceNotification;
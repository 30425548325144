import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import i18n from './i18n';
import { I18nextProvider } from "react-i18next";
import "react-image-lightbox/style.css";
import 'semantic-ui-less/semantic.less'
import 'jsoneditor-react/es/editor.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

import React, { useState } from "react";
import {
  Grid,
  Button,
  Input,
  Dropdown,
} from "semantic-ui-react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import CompanySearch from "../CompanySearch";

const AdvanceSearchSubscription = ({ data, setData, getList }) => {

  const { i18n } = useTranslation()

  const isDebtor = [
    { key: 0, value: false, text: "همه" },
    { key: 1, value: true, text: "شرکت های دارای بدهی" },
  ];



  return (
    <div className=" border-none adv-srch d-flex flow-col glass-effect w-100 p-1">
      <Grid stackable={false} verticalAlign="bottom">
        <Grid.Column computer={3} tablet={16}>
          <p>از تاریخ</p>
          {i18n.language == "fa" ? (
            <DatePicker
              style={{
                width: "100%",
                minHeight: "43px",
              }}
              calendar={persian}
              locale={persian_fa}
              value={data.from == null ? new Date() : new Date(data.from)}
              calendarPosition="bottom-right"
              onChange={(e) =>
                setData("from", `${new Date(e).getFullYear()}-${new Date(e).getMonth()}-${new Date(e).getDate()}`)
              }
              name="from"
            />
          ) : (
            <DatePicker
              style={{
                width: "100%",
                minHeight: "43px",
              }}
              value={data.from == null ? new Date() : new Date(data.from)}
              calendarPosition="bottom-right"
              onChange={(e) =>
                setData("from", `${new Date(e).getFullYear()}-${new Date(e).getMonth()}-${new Date(e).getDate()}`)
              }
              name="from"
            />
          )}

        </Grid.Column>
        <Grid.Column computer={3} tablet={16}>
          <p>تا تاریخ</p>
          {i18n.language == "fa" ? (
            <DatePicker
              style={{
                width: "100%",
                minHeight: "43px",
              }}
              calendar={persian}
              locale={persian_fa}
              value={data.to == null ? new Date() : new Date(data.to)}
              calendarPosition="bottom-right"
              onChange={(e) =>
                setData("to", `${new Date(e).getFullYear()}-${new Date(e).getMonth()}-${new Date(e).getDate()}`)
              }
              name="to"
            />
          ) : (
            <DatePicker
              style={{
                width: "100%",
                minHeight: "43px",
              }}
              value={data.to == null ? new Date() : new Date(data.to)}
              calendarPosition="bottom-right"
              onChange={(e) =>
                setData("to", `${new Date(e).getFullYear()}-${new Date(e).getMonth()}-${new Date(e).getDate()}`)
              }
              name="to"
              
            />
          )}

        </Grid.Column>
        <Grid.Column computer={4} tablet={16}>
          <p>وضعیت</p>
          <Dropdown
            name="justDeptors"
            options={isDebtor}
            onChange={(e, data) =>
              setData("justDeptors", data.value)
            }
            placeholder="انتخاب کنید"
            selection
            fluid
            defaultValue={data.justDeptors}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={16}>
          <p>شرکت</p>
          <CompanySearch currentid={data.companyid} changeHandler={setData} />
        </Grid.Column>
        <Grid.Column computer={2} tablet={6}>
          <Button fluid primary onClick={() => getList()}>
            جستجو
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default AdvanceSearchSubscription;

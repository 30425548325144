import React from "react";
import {
  Grid
} from "semantic-ui-react";

import { Link } from "react-router-dom";
import DataPopup from "../DataPopup";

const SubscriptionAllCard = ({ item }) => {

  return (
    <>
      <Grid.Column width={16}>
        <Grid className="subsc-card p-0" verticalAlign="middle">

          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            کد عضویت انجمن
          </Grid.Column>
          <Grid.Column className="p-8px " computer={1} tablet={11}>
            {item.id ? item.id : "-"}
          </Grid.Column>

          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            شرح
          </Grid.Column>
          <Grid.Column className="p-1" computer={3} tablet={11}>
            {/* {item.comment} */}

            <DataPopup value={
              <div className="d-flex flex-flow-row ai-center jc-center flex-wrap txt-ellip">
                {item.comment.split(",").map((itm, index) => {
                  return <label class="ui label m-05 basic" key={index}>{itm}</label>
                })}
              </div>
            }
              text={<div className="txt-ellip">
                {item.comment.split(",").map((itm, index) => {
                  return <label class="ui label m-1px-b basic" key={index}>{itm}</label>
                })}
              </div>} />


          </Grid.Column>

          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            شرکت
          </Grid.Column>
          <Grid.Column className="p-8px " computer={2} tablet={11}>
            {item?.company?.name ? item.company.name : "-"}
          </Grid.Column>


          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            سال
          </Grid.Column>

          <Grid.Column computer={2} tablet={11} className="p-1">
            <DataPopup value={
              <div className="d-flex flex-flow-row ai-center jc-center flex-wrap txt-ellip">
                {item.year.split(",").map((itm, index) => {
                  return <label class="ui label m-05" key={index}>{itm}</label>
                })}
              </div>
            }
              text={<div className="txt-ellip">
                {item.year.split(",").map((itm, index) => {
                  return <label class="ui label m-1px-b" key={index}>{itm}</label>
                })}
              </div>} />

          </Grid.Column>

          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            صورت حساب
          </Grid.Column>
          <Grid.Column className="p-1" computer={2} tablet={11}>
            {Number(item.totalprice).toLocaleString()} ریال
          </Grid.Column>


          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            بدهی
          </Grid.Column>
          <Grid.Column className="p-1" computer={2} tablet={11}>
            {Number(item.totaldebt).toLocaleString()} ریال
          </Grid.Column>


          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            پرداختی
          </Grid.Column>
          <Grid.Column className="p-1" computer={2} tablet={11}>
            {Number(item.totalpaid).toLocaleString()} ریال
          </Grid.Column>



          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            وضعیت پرداخت
          </Grid.Column>
          <Grid.Column className="p-0" computer={1} tablet={11}>

            {item.ispaid == true
              ? <label style={{ whiteSpace: 'nowrap' }} className="ui label green small">پرداخت کامل</label>
              : <label style={{ whiteSpace: 'nowrap' }} className="ui label red small">دارای بدهی</label>}
          </Grid.Column>

          <Grid.Column className="autoShow color-grey p-8px" tablet={5}>
            عملیات
          </Grid.Column>
          <Grid.Column className="p-0" computer={1} tablet={11}>
            <Link
              style={{ whiteSpace: 'nowrap', fontSize:'12px' }}
              to={`/subscription/${item?.company?.id}`}
            >
              سوابق مالی
            </Link>
          </Grid.Column>

        </Grid>
      </Grid.Column>
    </>
  );
};

export default SubscriptionAllCard;

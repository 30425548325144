import ServiceApi from "../services/services.api";
ServiceApi.defaults.headers.common['lang'] = localStorage.getItem("langAdmin") ? localStorage.getItem("langAdmin") : 'fa';

const ApiCompany = {
  getCompanyProfile: () => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/view`,
    };
    return ServiceApi(options);
  },
  saveCompanyProfile: (data) => {
    const options = {
      method: 'POST',
      data:data,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/save`,
    };
    return ServiceApi(options);
  },
  getCompanyBySlug: (slug) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/get/plus/${slug}`,
    };
    return ServiceApi(options);
  },
  getCompanyById: (id) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/get/plus/id/${id}`,
    };
    return ServiceApi(options);
  },
  getCompanyList: (skip , take) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/get/list?skip=${skip}&take=${take}`,
    };
    return ServiceApi(options);
  },
  getCompanyByUser: (userid, skip, take) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/get/user/list?userid=${userid}&skip=${skip}&take=${take}`,
    };
    return ServiceApi(options);
  },

  deleteProjectById: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/project/delete/${id}`,
    };
    return ServiceApi(options);
  },
  deleteRateById: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/rate/delete/${id}`,
    };
    return ServiceApi(options);
  },
  deleteBranchById: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/branch/delete/${id}`,
    };
    return ServiceApi(options);
  },
  setCompanyStatus: (id, status) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`
      },
      url: `/company/set/${id}/status/${status}`,
    };
    return ServiceApi(options);
  },
  getCompanyListSearch: (skip, take, data,activeStatus = "all") => {
    var query = ""
    if (data.name != null) {
      query = query + `&name=${data.name}`
    }
    if (data.category != null) {
      query = query + `&category=${data.category}`
    }
    if (data.isAdviser != null) {
      query = query + `&isAdviser=${data.isAdviser}`
    }
    if (data.nationCode != null) {
      query = query + `&nationCode=${data.nationCode}`
    }
     if (data.isDebtor != null) {
      query = query + `&isDebtor=${data.isDebtor}`
    }
    if(activeStatus != "all"){
      query = query + `&isActive=${activeStatus}`

    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
        'lang': localStorage.getItem("langAdmin"),
      },
      url: `/company/get/list?skip=${skip}&take=${take}${query}`,
    };
    return ServiceApi(options);
  },
  deleteCompanyById: (id) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/delete/${id}`,
    };
    return ServiceApi(options);
  },
  duplicateCompany: (id, lang) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenAdmin')}`,
      },
      url: `/company/duplicate/${id}/lang/${lang}`,
    };
    return ServiceApi(options);
  },
};
export default ApiCompany;

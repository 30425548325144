import React, { useEffect, useState } from "react";
import { Search } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import ApiCompany from "../../core/api/api.company";

const CompanySearch = ({ currentid, changeHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (currentid != 0 && currentid != "") {
      getUserHandler(currentid);
    } else {
      setValue("");
    }
  }, [currentid]);

  const getUserHandler = async (value) => {
    try {
      const response = await ApiCompany.getCompanyById(value);
      if (response.data.status === "success") {
        setValue(response.data.data.company.name);
      }
    } catch (e) {
      console.log(e);
      if (e.response.status == 401) {
        navigate("/login");
      }
    }
  };

  const handleResultSelect = (e, { result }) => {
    setValue(result.title);
    changeHandler("companyid", result.id, false);
  };

  const handleSearchChange = (e, { value }) => {
    setValue(value);
    setIsLoading(true);
    getUserListHandler(value);
  };
  const getUserListHandler = async (value) => {
    try {
      const response = await ApiCompany.getCompanyListSearch(0,30, {name: value});
      if (response.data.status === "success") {
        const res = response.data.data.map((item) => ({
          id: item.company.id,
          title: `${item.company.name}`,
        }));
        setResults(res);
      }
    } catch (e) {
      console.log(e);
      if (e.response.status == 401) {
        navigate("/login");
      }
    }
  };

  return (
    <>
      <Search
        fluid
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        onFocus={handleSearchChange}
        icon={false}
        results={results}
        value={value}
        name="companyid"
        className="search-input"
        minCharacters={3}
        noResultsMessage="جستجو بدون نتیجه"
      />
    </>
  );
};
export default CompanySearch;

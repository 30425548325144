import axios from "axios/index";

const env = process.env;
const ServiceApi = axios;

ServiceApi.defaults.timeout = 20000;
ServiceApi.defaults.baseURL = env.REACT_APP_API_URL;


export default ServiceApi;

import { createContext, useState } from 'react';

const MyContext = createContext("");


const MyContextProvider = ({ children }) => {
    const [userInformation, setUserInfo] = useState(null);

    const updateData = (newData) => {
        setUserInfo(newData);
    };

    const contextValue = {
        userInformation,
        updateData, // Include the update function in the context value
    };

    return (
        <MyContext.Provider value={contextValue}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext, MyContextProvider }